import { somenteComAutenticacao$Permissao } from '@/router/rotas.guardas'
import { CLIENTES } from '@/permissoes.config'

const CAMINHO_RAIZ = '/clientes'

const rotas = [
  {
    path: `${CAMINHO_RAIZ}`,
    name: 'rota-clientes',
    meta: {
      layout: 'l-barra-lateral',
      permissao: CLIENTES,
    },
    beforeEnter: somenteComAutenticacao$Permissao,
    component: () =>
      import(
        /* webpackChunkName: "clientes" */ '@/views/Clientes/Lista/Cliente.vue'
      ),
  },
  {
    path: `${CAMINHO_RAIZ}/cadastrar`,
    name: 'rota-cliente-cadastrar',
    meta: {
      layout: 'l-barra-lateral',
      permissao: CLIENTES,
    },
    beforeEnter: somenteComAutenticacao$Permissao,
    component: () =>
      import(
        /* webpackChunkName: "clientes" */ '@/views/Clientes/Cadastro/Cliente.vue'
      ),
  },
  {
    path: `${CAMINHO_RAIZ}/alterar/:clienteId`,
    name: 'rota-cliente-alterar',
    meta: {
      layout: 'l-barra-lateral',
      permissao: CLIENTES,
    },
    beforeEnter: somenteComAutenticacao$Permissao,
    component: () =>
      import(
        /* webpackChunkName: "clientes" */ '@/views/Clientes/Cadastro/Cliente.vue'
      ),
  },
]

export default rotas
