<template>
  <validation-provider
    :vid="id"
    :name="id"
    :rules="validations"
    v-slot="{ errors }"
  >
    <v-autocomplete
      :label="label"
      :disabled="disabled"
      :items="cidades"
      :error-messages="errors"
      v-model="inputValue"
      :clearable="clearable"
      :autocomplete="false"
    />
  </validation-provider>
</template>

<script>
import cidadesData from './cidades.json'
import InputMixin from '../InputMixin'

export default {
  mixins: [InputMixin],
  props: {
    uf: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cidades: [],
    }
  },
  mounted() {
    let listaUfs = cidadesData?.estados
    if (this.uf) {
      listaUfs = listaUfs.filter((item) => item.sigla === this.uf)
    }

    listaUfs?.forEach((item) => this.cidades.push(...item.cidades))
  },
}
</script>
