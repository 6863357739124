<template>
  <validation-provider
    :vid="id"
    :name="id"
    :rules="validations"
    v-slot="{ errors }"
  >
    <v-select
      :label="label"
      :items="items"
      :error-messages="errors"
      :placeholder="placeholder"
      :disabled="disabled"
      :clearable="clearable"
      item-text="text"
      item-value="value"
      v-model="inputValue"
      @blur="blur"
    />
  </validation-provider>
</template>

<script>
import InputMixin from './InputMixin'

export default {
  mixins: [InputMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>
