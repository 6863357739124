export const AGENDA = 'AGENDA'
export const PEDIDOS = 'PEDIDOS'

export const PRODUTOS = 'PRODUTOS'
export const ENTREGADORES = 'ENTREGADORES'
export const CLIENTES = 'CLIENTES'

export const DADOS_DA_EMPRESA = 'DADOS_DA_EMPRESA'
export const CARGOS = 'CARGOS'
export const USUARIOS = 'USUARIOS'
export const IMPRESSORAS = 'IMPRESSORAS'
