import { somenteComAutenticacao$Permissao } from '@/router/rotas.guardas'
import { DADOS_DA_EMPRESA } from '@/permissoes.config'

const CAMINHO_RAIZ = '/empresa'

const rotas = [
  {
    path: `${CAMINHO_RAIZ}`,
    name: 'rota-empresa',
    meta: {
      layout: 'l-barra-lateral',
      permissao: DADOS_DA_EMPRESA,
    },
    beforeEnter: somenteComAutenticacao$Permissao,
    component: () =>
      import(/* webpackChunkName: "empresa" */ '@/views/Empresa/Cadastro.vue'),
  },
]

export default rotas
