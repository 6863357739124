<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <v-form
      class="formulario-basico"
      :style="cssForm"
      @submit.stop.prevent="handleSubmit(salvarDados)"
    >
      <slot />
      <v-card-actions class="formulario-basico__botoes">
        <v-spacer />
        <slot name="botoes" />
      </v-card-actions>
    </v-form>
  </validation-observer>
</template>

<script>
export default {
  props: {
    salvar: {
      type: Function,
      required: true,
    },
    cssColunas: {
      required: false,
      type: Number,
      default: 1,
    },
  },
  computed: {
    cssForm() {
      return `grid-template-columns: repeat(${this.cssColunas}, 1fr);`
    },
  },
  methods: {
    salvarDados() {
      this.salvar()
      this.$refs.observer.reset()
    },
  },
}
</script>

<style lang="scss" scoped>
.formulario-basico {
  display: flex;
  flex-direction: column;

  min-width: $custom-extra-small-size;
  @media (min-width: $custom-medium-size) {
    display: grid;
    grid-column-gap: 1rem;
  }

  &__botoes {
    @media (min-width: $custom-medium-size) {
      flex-direction: row;
      justify-content: flex-end;
      grid-column: 1 / -1;
    }
  }
}
</style>
