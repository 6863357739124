<template>
  <validation-provider
    :vid="id"
    :name="id"
    :rules="validations"
    v-slot="{ errors }"
  >
    <v-text-field
      :ref="`ref${id}`"
      :label="label"
      v-model="inputValue"
      :error-messages="errors"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
      :clearable="clearable"
      :autocomplete="false"
      @blur="blur"
    />
  </validation-provider>
</template>

<script>
import InputMixin from './InputMixin'

export default {
  mixins: [InputMixin],
  props: {
    type: {
      required: false,
      default: 'text',
    },
  },
}
</script>
