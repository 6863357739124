<template>
  <validation-provider
    :vid="id"
    :name="id"
    :rules="validations"
    v-slot="{ errors }"
  >
    <v-select
      :label="label"
      :items="items"
      :error-messages="errors"
      :placeholder="placeholder"
      :disabled="disabled"
      :clearable="clearable"
      item-text="text"
      item-value="value"
      v-model="inputValue"
      @blur="blur"
    />
  </validation-provider>
</template>

<script>
import InputMixin from './InputMixin'

export default {
  mixins: [InputMixin],
  data() {
    return {
      items: [
        {
          text: 'Dinheiro',
          value: 'dinheiro',
        },
        {
          text: 'PIX',
          value: 'pix',
        },
        {
          text: 'Boleto',
          value: 'boleto',
        },
        {
          text: 'Cartão débito',
          value: 'cartao_debito',
        },
        {
          text: 'Cartão crédito',
          value: 'cartao_credito',
        },
        {
          text: 'Vale alimentação',
          value: 'vale_alimentacao',
        },
        {
          text: 'Vale refeição',
          value: 'vale_refeicao',
        },
      ],
    }
  },
}
</script>
