<template>
  <v-app>
    <!-- <v-app-bar v-if="$vuetify.breakpoint.xsOnly" app>
      <v-app-bar-nav-icon @click="abrirMenus"></v-app-bar-nav-icon>
    </v-app-bar> -->
    <Navegacao ref="navegacaoMenu" />
    <v-main class="">
      <div class="barra-lateral">
        <slot />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Navegacao from './Navegacao.vue'

export default {
  components: {
    Navegacao,
  },
  methods: {
    abrirMenus() {
      this.$refs.navegacaoMenu.abrirMenu()
    },
  },
}
</script>

<style lang="scss" scoped>
.barra-lateral {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #f4f7fa;
}
</style>
