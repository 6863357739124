import MensagensHelper, { MSG_ERRO, MSG_ALERTA } from '@/utils/mensagem.helper'

export default {
  validar(erro) {
    if (!erro.response.data) {
      return MensagensHelper.gerar(
        MSG_ERRO,
        'Ops, Não foi possível conectar ao servidor'
      )
    }

    if (!erro.response.data.errors) {
      return MensagensHelper.gerar(
        MSG_ERRO,
        'Ops, Um comportamente estranho ocorreu nos servidores'
      )
    }

    return MensagensHelper.gerar(MSG_ALERTA, erro.response.data.errors)
  },
}
