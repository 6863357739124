import { somenteComAutenticacao$Permissao } from '@/router/rotas.guardas'
import { CARGOS } from '@/permissoes.config'

const CAMINHO_RAIZ = '/cargos'

const rotas = [
  {
    path: `${CAMINHO_RAIZ}`,
    name: 'rota-cargos',
    meta: {
      layout: 'l-barra-lateral',
      permissao: CARGOS,
    },
    beforeEnter: somenteComAutenticacao$Permissao,
    component: () =>
      import(/* webpackChunkName: "cargos" */ '@/views/Cargos/Lista/Cargo.vue'),
  },
  {
    path: `${CAMINHO_RAIZ}/cadastrar`,
    name: 'rota-cargo-cadastrar',
    meta: {
      layout: 'l-barra-lateral',
      permissao: CARGOS,
    },
    beforeEnter: somenteComAutenticacao$Permissao,
    component: () =>
      import(
        /* webpackChunkName: "cargos" */ '@/views/Cargos/Cadastro/Cargo.vue'
      ),
  },
  {
    path: `${CAMINHO_RAIZ}/alterar/:cargoId`,
    name: 'rota-cargo-alterar',
    meta: {
      layout: 'l-barra-lateral',
      permissao: CARGOS,
    },
    beforeEnter: somenteComAutenticacao$Permissao,
    component: () =>
      import(
        /* webpackChunkName: "cargos" */ '@/views/Cargos/Cadastro/Cargo.vue'
      ),
  },
]

export default rotas
