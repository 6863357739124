import Vue from 'vue'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import ptBr from './pt-BR.json'

localize('pt_BR', ptBr)

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

extend('validaLenCel', (value) => {
  if (value === undefined || value === null) return false

  if (value.replace(/\D/g, '').length >= 12) {
    return true
  }

  return `O campo  deve possuir o DDD e mais 8 dígitos`
})

extend('validaCpfCnpj', (value) => {
  if (value === undefined || value === null) return false

  if (value.replace(/\D/g, '').length >= 11) {
    return true
  }

  return `O campo deve possuir no minimo 11 dígitos`
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
