<template>
  <v-card>
    <v-card-title v-if="titulo">{{ titulo }}</v-card-title>
    <v-card-text>
      <slot />
    </v-card-text>
    <v-card-actions v-if="barraBotoes">
      <v-spacer />
      <slot name="botoes" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    titulo: {
      type: String,
      default: '',
    },
    barraBotoes: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
