import axios from 'axios'
import errorHandler from './error-handler'

const ROTA = 'https://app.bembomsalgados.com.br/teleentrega/entregadores'

export default {
  async cadastrar(entregadorDto) {
    try {
      await axios.post(`${ROTA}`, entregadorDto)
    } catch (error) {
      throw errorHandler.validar(error)
    }
  },
  async atualizar(entregadorDto) {
    try {
      await axios.put(`${ROTA}`, entregadorDto)
    } catch (error) {
      throw errorHandler.validar(error)
    }
  },
  async buscaPorId(entregadorId) {
    try {
      const { data } = await axios.get(`${ROTA}/filtrar-id/${entregadorId}`)
      return data
    } catch (error) {
      throw errorHandler.validar(error)
    }
  },
  async buscaTodos(pagina = 1) {
    try {
      const { data } = await axios.get(`${ROTA}/todos?pagina=${pagina}`)
      return data
    } catch (error) {
      throw errorHandler.validar(error)
    }
  },
  async buscaTodosSemLimite() {
    try {
      const { data } = await axios.get(`${ROTA}/todos-sem-limite`)
      return data
    } catch (error) {
      throw errorHandler.validar(error)
    }
  },
}
