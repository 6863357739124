import store from '@/store'
import Vue from 'vue'

export const somenteSemAutenticacao = (to, from, next) => {
  if (store.getters['estaAutenticado']) {
    return next({
      name: 'rota-pedidos-painel',
    })
  }

  next()
}

export const somenteComAutenticacao = (to, from, next) => {
  if (store.getters['estaAutenticado']) {
    return next()
  }

  Vue.$toast.warning(
    'Você precisa estar autenticado para acessar este recurso.',
    {
      position: 'top-right',
    }
  )

  store.dispatch('sairDoSistemaStore')
  next({
    name: 'rota-login',
  })
}

export const somenteComAutenticacao$Permissao = (to, from, next) => {
  if (store.getters['estaAutenticado']) {
    if (
      store.getters['rotaMestre'] ||
      store.getters['rotas'].find((rota) => rota === to?.meta?.permissao)
    ) {
      return next()
    }
  }

  Vue.$toast.warning('Você não tem autorização para acessar este recurso.', {
    position: 'top-right',
  })

  store.dispatch('sairDoSistemaStore')
  next({
    name: 'rota-login',
  })
}
