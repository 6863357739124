<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
export default {
  name: 'App',
  computed: {
    layout() {
      return this.$route.meta.layout || 'l-basico'
    },
  },
}
</script>

<style lang="scss">
html {
  overflow-y: auto !important;
}
</style>
