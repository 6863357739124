<template>
  <v-dialog v-model="mostrar" persistent max-width="500">
    <v-card>
      <v-card-title>
        {{ titulo }}
      </v-card-title>
      <v-card-text>{{ mensagem }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel">Não</v-btn>
        <v-btn color="primary" @click="ok">Sim</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    titulo: {
      type: String,
      required: true,
    },
    mensagem: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      resolve: null,
      mostrar: false,
    }
  },
  methods: {
    pop() {
      this.mostrar = true
      return new Promise((resolve) => {
        this.resolve = resolve
      })
    },
    ok() {
      this.mostrar = false
      this.resolve(true)
    },
    cancel() {
      this.mostrar = false
      this.resolve(false)
    },
  },
}
</script>
