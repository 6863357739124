import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

export const adicionaDia = (data, dias = 1) => {
  return dayjs(data).add(dias, 'day').format('YYYY-MM-DD')
}

export const adicionaSegundos = (data, segundos = 1) => {
  return dayjs(data).add(segundos, 's').tz('America/Sao_Paulo').toDate()
}

export const adicionaMinutos = (data, minutos = 1) => {
  return dayjs(data).add(minutos, 'm').tz('America/Sao_Paulo').toDate()
}

export const adicionaHoras = (data, horas = 1) => {
  return dayjs(data).add(horas, 'h').tz('America/Sao_Paulo').toDate()
}

export const paraDataUtcBR = (data) => {
  return dayjs.utc(data).tz('America/Sao_Paulo').toDate()
}

export const paraDataStringUtcBR = (data) => {
  return dayjs.utc(data).tz('America/Sao_Paulo').toDate().toString()
}

export const paraDataYYYYMMDD = (data) => {
  return dayjs.tz(data, 'America/Sao_Paulo').format('YYYY-MM-DD')
}

export const paraDataDDMMYYYY = (data) => {
  return dayjs.tz(data, 'America/Sao_Paulo').format('DD/MM/YYYY')
}

export const paraHora = (data) => {
  return dayjs.tz(data, 'America/Sao_Paulo').format('HH:mm')
}
