import { somenteComAutenticacao$Permissao } from '@/router/rotas.guardas'
import { USUARIOS } from '@/permissoes.config'

const CAMINHO_RAIZ = '/usuarios'

const rotas = [
  {
    path: `${CAMINHO_RAIZ}`,
    name: 'rota-usuarios',
    meta: {
      layout: 'l-barra-lateral',
      permissao: USUARIOS,
    },
    beforeEnter: somenteComAutenticacao$Permissao,
    component: () =>
      import(
        /* webpackChunkName: "usuarios" */ '@/views/Usuarios/Lista/Usuario.vue'
      ),
  },
  {
    path: `${CAMINHO_RAIZ}/cadastrar`,
    name: 'rota-usuario-cadastrar',
    meta: {
      layout: 'l-barra-lateral',
      permissao: USUARIOS,
    },
    beforeEnter: somenteComAutenticacao$Permissao,
    component: () =>
      import(
        /* webpackChunkName: "usuarios" */ '@/views/Usuarios/Cadastro/Usuario.vue'
      ),
  },
  {
    path: `${CAMINHO_RAIZ}/alterar/:usuarioId`,
    name: 'rota-usuario-alterar',
    meta: {
      layout: 'l-barra-lateral',
      permissao: USUARIOS,
    },
    beforeEnter: somenteComAutenticacao$Permissao,
    component: () =>
      import(
        /* webpackChunkName: "usuarios" */ '@/views/Usuarios/Cadastro/Usuario.vue'
      ),
  },
]

export default rotas
