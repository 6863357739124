import axios from 'axios'
import errorHandler from './error-handler'

const ROTA = 'https://app.bembomsalgados.com.br/teleentrega/usuarios'

export default {
  async cadastrar(usuarioDto) {
    try {
      await axios.post(`${ROTA}`, usuarioDto)
    } catch (error) {
      throw errorHandler.validar(error)
    }
  },
  async atualizar(usuarioDto) {
    try {
      await axios.put(`${ROTA}`, usuarioDto)
    } catch (error) {
      throw errorHandler.validar(error)
    }
  },
  async buscaPorId(produtoId) {
    try {
      const { data } = await axios.get(`${ROTA}/filtrar-id/${produtoId}`)
      return data
    } catch (error) {
      throw errorHandler.validar(error)
    }
  },
  async buscaTodos(pagina = 1) {
    try {
      const { data } = await axios.get(`${ROTA}/todos?pagina=${pagina}`)
      return data
    } catch (error) {
      throw errorHandler.validar(error)
    }
  },
  async login(loginDto) {
    try {
      const { data } = await axios.post(`${ROTA}/login`, loginDto)
      return data
    } catch (error) {
      throw errorHandler.validar(error)
    }
  },
}
