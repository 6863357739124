<template>
  <validation-provider
    :vid="id"
    :name="id"
    :rules="validations"
    v-slot="{ errors }"
  >
    <v-text-field
      :label="label"
      v-model="inputValue"
      :error-messages="errors"
      :placeholder="placeholder"
      :disabled="disabled"
      :clearable="clearable"
      :autocomplete="false"
      v-mask="mascara"
      type="text"
      @blur="blur"
    />
  </validation-provider>
</template>

<script>
import { mask } from 'vue-the-mask'
import InputMixin from './InputMixin'

export default {
  mixins: [InputMixin],
  directives: {
    mask,
  },
  data() {
    return {
      mascara: '+55 (##)# ####-####',
    }
  },
  watch: {
    inputValue: function (newValue) {
      const somenteNumeros = newValue.replace(/\D/g, '')
      this.mascara = '+55 (##)# ####-####'
    },
  },
}
</script>
