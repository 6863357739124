import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/plugins/vee-validate'
import '@/plugins/layout'
import '@/plugins/component'
import '@/plugins/vue-currency-filter'
import '@/plugins/vue-loading-overlay'
import '@/plugins/custom-filter'
import '@/plugins/vue-toast-notification'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
