import { somenteComAutenticacao$Permissao } from '@/router/rotas.guardas'
import { PEDIDOS, AGENDA } from '@/permissoes.config'

const CAMINHO_RAIZ = '/pedidos'

const rotas = [
  {
    path: `${CAMINHO_RAIZ}/agenda`,
    name: 'rota-pedidos-agenda',
    meta: {
      layout: 'l-barra-lateral',
      permissao: AGENDA,
    },
    beforeEnter: somenteComAutenticacao$Permissao,
    component: () =>
      import(
        /* webpackChunkName: "pedidos" */ '@/views/Pedidos/Agenda/Agenda.vue'
      ),
  },
  {
    path: `${CAMINHO_RAIZ}/painel`,
    name: 'rota-pedidos-painel',
    meta: {
      layout: 'l-barra-lateral',
      permissao: PEDIDOS,
    },
    beforeEnter: somenteComAutenticacao$Permissao,
    component: () =>
      import(
        /* webpackChunkName: "pedidos" */ '@/views/Pedidos/Painel/Painel.vue'
      ),
  },
]

export default rotas
