import { somenteComAutenticacao$Permissao } from '@/router/rotas.guardas'
import { PRODUTOS } from '@/permissoes.config'

const CAMINHO_RAIZ = '/produtos'

const rotas = [
  {
    path: `${CAMINHO_RAIZ}`,
    name: 'rota-produtos',
    meta: {
      layout: 'l-barra-lateral',
      permissao: PRODUTOS,
    },
    beforeEnter: somenteComAutenticacao$Permissao,
    component: () =>
      import(
        /* webpackChunkName: "produtos" */ '@/views/Produtos/Lista/Produto.vue'
      ),
  },
  {
    path: `${CAMINHO_RAIZ}/cadastrar`,
    name: 'rota-produtos-cadastrar',
    meta: {
      layout: 'l-barra-lateral',
      permissao: PRODUTOS,
    },
    beforeEnter: somenteComAutenticacao$Permissao,
    component: () =>
      import(
        /* webpackChunkName: "produtos" */ '@/views/Produtos/Cadastro/Produto.vue'
      ),
  },
  {
    path: `${CAMINHO_RAIZ}/alterar/:produtoId`,
    name: 'rota-produtos-alterar',
    meta: {
      layout: 'l-barra-lateral',
      permissao: PRODUTOS,
    },
    beforeEnter: somenteComAutenticacao$Permissao,
    component: () =>
      import(
        /* webpackChunkName: "produtos" */ '@/views/Produtos/Cadastro/Produto.vue'
      ),
  },
]

export default rotas
