var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"vid":_vm.id,"name":_vm.id,"rules":_vm.validations},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"currency",rawName:"v-currency",value:({
      currency: null,
      locale: 'en',
      allowNegative: false,
      valueRange: { min: 0 },
    }),expression:"{\n      currency: null,\n      locale: 'en',\n      allowNegative: false,\n      valueRange: { min: 0 },\n    }"}],attrs:{"label":_vm.label,"error-messages":errors,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"clearable":_vm.clearable,"autocomplete":false},on:{"blur":_vm.blur},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }