import Vue from 'vue'

import LayoutBasico from '@/components/Layout/Basico.vue'
import LayoutBarraLateral from '@/components/Layout/BarraLateral/BarraLateral.vue'
import TelaBasica from '@/components/Tela/Basico.vue'
import TelaConsulta from '@/components/Tela/Consulta.vue'
import FormularioBasico from '@/components/Formulario/Basico.vue'
import ModalConfirmacao from '@/components/Modal/Confirmacao.vue'

Vue.component('l-basico', LayoutBasico)
Vue.component('l-barra-lateral', LayoutBarraLateral)
Vue.component('l-tela', TelaBasica)
Vue.component('l-consulta', TelaConsulta)
Vue.component('l-form', FormularioBasico)
Vue.component('m-confirmacao', ModalConfirmacao)
