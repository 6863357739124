import axios from 'axios'

const USERKEY = '_projetoteleentrega'
const PERMISSOESKEY = '_projetoteleentrega_permissoes'

const temKey = (key) => localStorage.getItem(key) !== null

const autenticacaoStorage = {
  adicionaAuthorization(token) {
    axios.defaults.headers.common.Authorization = token
    axios.defaults.headers.common['Content-Type'] = 'application/json'
  },

  getUserObj() {
    if (temKey(USERKEY)) {
      const { token } = JSON.parse(localStorage.getItem(USERKEY))
      this.adicionaAuthorization(token)
    }

    return temKey(USERKEY) ? JSON.parse(localStorage.getItem(USERKEY)) : null
  },

  setUserObj(userObj) {
    localStorage.setItem(USERKEY, JSON.stringify(userObj))
    this.adicionaAuthorization(userObj.token)
  },

  removeUserObj() {
    if (temKey(USERKEY)) {
      localStorage.removeItem(USERKEY)
    }

    if (temKey(PERMISSOESKEY)) {
      localStorage.removeItem(PERMISSOESKEY)
    }

    delete axios.defaults.headers.common.Authorization
  },

  salvaPermissoesStorage(valor) {
    localStorage.setItem(PERMISSOESKEY, JSON.stringify(valor))
  },

  buscaPermissoesStorage() {
    if (temKey(PERMISSOESKEY)) {
      return JSON.parse(localStorage.getItem(PERMISSOESKEY))
    }

    return null
  },
}

export default autenticacaoStorage
