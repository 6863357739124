import * as permissao from './permissoes.config'

const menus = [
  {
    id: 'menu-atendimento',
    label: 'Atendimento',
    icone: 'mdi-calendar-blank',
    menus: [
      {
        id: 'menu-atendimento-agenda',
        label: 'Agenda',
        rota: 'rota-pedidos-agenda',
        permissaoNecessaria: permissao.AGENDA,
      },
      {
        id: 'menu-atendimento-painel',
        label: 'Painel de pedidos',
        rota: 'rota-pedidos-painel',
        permissaoNecessaria: permissao.PEDIDOS,
      },
    ],
  },
  {
    id: 'menu-cadastro',
    label: 'Cadastros',
    icone: 'mdi-plus',
    menus: [
      {
        id: 'menu-cadastro-produtos',
        label: 'Produtos',
        rota: 'rota-produtos',
        permissaoNecessaria: permissao.PRODUTOS,
      },
      {
        id: 'menu-cadastro-entregadores',
        label: 'Entregadores',
        rota: 'rota-entregadores',
        permissaoNecessaria: permissao.ENTREGADORES,
      },
      {
        id: 'menu-cadastro-clientes',
        label: 'Clientes',
        rota: 'rota-clientes',
        permissaoNecessaria: permissao.CLIENTES,
      },
    ],
  },
  {
    id: 'menu-configuracoes',
    label: 'Configurações',
    icone: 'mdi-cog',
    menus: [
      {
        id: 'menu-dados-empresa',
        label: 'Dados da empresa',
        rota: 'rota-empresa',
        permissaoNecessaria: permissao.DADOS_DA_EMPRESA,
      },
      {
        id: 'menu-cadastro-cargos',
        label: 'Cargos',
        rota: 'rota-cargos',
        permissaoNecessaria: permissao.CARGOS,
      },
      {
        id: 'menu-cadastro-usuarios',
        label: 'Usuários',
        rota: 'rota-usuarios',
        permissaoNecessaria: permissao.USUARIOS,
      },
    ],
  },
]

export default menus
