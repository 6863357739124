import Vue from 'vue'
import VueRouter from 'vue-router'
import { somenteSemAutenticacao, somenteComAutenticacao } from './rotas.guardas'

import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import produtoRotas from '@/views/Produtos/rotas'
import pedidoRotas from '@/views/Pedidos/rotas'
import entregadorRotas from '@/views/Entregadores/rotas'
import clienteRotas from '@/views/Clientes/rotas'
import cargoRotas from '@/views/Cargos/rotas'
import empresaRotas from '@/views/Empresa/rotas'
import usuarioRotas from '@/views/Usuarios/rotas'

Vue.use(VueRouter)

const routes = [
  {
    path: '/painel',
    name: 'rota-painel',
    meta: {
      layout: 'l-barra-lateral',
    },
    beforeEnter: somenteComAutenticacao,
    component: Home,
  },
  {
    path: '/',
    name: 'rota-login',
    beforeEnter: somenteSemAutenticacao,
    component: Login,
  },
  ...produtoRotas,
  ...pedidoRotas,
  ...entregadorRotas,
  ...clienteRotas,
  ...cargoRotas,
  ...empresaRotas,
  ...usuarioRotas,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
