export default {
  props: {
    value: {
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validations: {
      type: Object,
      default: () => ({}),
    },
    tudoLower: {
      type: Boolean,
      default: false,
    },
    tudoUpper: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    blur: {
      type: Function,
      default: function () {},
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(newValue) {
        if (newValue !== this.value) {
          if (this.tudoLower) {
            this.$emit('input', newValue.toLowerCase())
          } else if (this.tudoUpper) {
            this.$emit('input', newValue.toUpperCase())
          } else {
            this.$emit('input', newValue)
          }
        }
      },
    },
  },
  methods: {
    getValidationState({ validated, invalid }) {
      if (validated) {
        if (invalid) {
          return false
        }
      }

      return null
    },
    definirFoco() {
      setTimeout(() => {
        this.$refs[`ref${this.id}`].focus()
      }, 100)
    },
  },
}
