import Vue from 'vue'
import { paraDataDDMMYYYY, paraHora } from '@/utils/data.utils'

Vue.filter('ddmmyyyy', function (value) {
  if (!value) return ''

  return paraDataDDMMYYYY(value)
})

Vue.filter('hhmm', function (value) {
  if (!value) return ''

  return paraHora(value)
})
