import { somenteComAutenticacao$Permissao } from '@/router/rotas.guardas'
import { ENTREGADORES } from '@/permissoes.config'

const CAMINHO_RAIZ = '/entregadores'

const rotas = [
  {
    path: `${CAMINHO_RAIZ}`,
    name: 'rota-entregadores',
    meta: {
      layout: 'l-barra-lateral',
      permissao: ENTREGADORES,
    },
    beforeEnter: somenteComAutenticacao$Permissao,
    component: () =>
      import(
        /* webpackChunkName: "entregadores" */ '@/views/Entregadores/Lista/Entregador.vue'
      ),
  },
  {
    path: `${CAMINHO_RAIZ}/cadastrar`,
    name: 'rota-entregador-cadastrar',
    meta: {
      layout: 'l-barra-lateral',
      permissao: ENTREGADORES,
    },
    beforeEnter: somenteComAutenticacao$Permissao,
    component: () =>
      import(
        /* webpackChunkName: "entregadores" */ '@/views/Entregadores/Cadastro/Entregador.vue'
      ),
  },
  {
    path: `${CAMINHO_RAIZ}/alterar/:entregadorId`,
    name: 'rota-entregador-alterar',
    meta: {
      layout: 'l-barra-lateral',
      permissao: ENTREGADORES,
    },
    beforeEnter: somenteComAutenticacao$Permissao,
    component: () =>
      import(
        /* webpackChunkName: "entregadores" */ '@/views/Entregadores/Cadastro/Entregador.vue'
      ),
  },
]

export default rotas
