import Vue from 'vue'
import Vuex from 'vuex'
import usuarioService from '@/services/usuario.service'
import cargoService from '@/services/cargo.service'
import localstorage from '@/utils/localstorage.utils'

Vue.use(Vuex)

const TOKEN_ADICIONAR = 'TOKEN_ADICIONAR'
const TOKEN_REMOVER = 'TOKEN_REMOVER'
const ROTAS_ADICIONAR = 'ROTAS_ADICIONAR'

export default new Vuex.Store({
  state: {
    dadosUsuario: localstorage.getUserObj(),
    autenticado: localstorage.getUserObj() !== null,
    cargoPermissoes: localstorage.buscaPermissoesStorage(),
  },
  mutations: {
    [TOKEN_ADICIONAR](state, payload) {
      state.dadosUsuario = payload
      state.autenticado = true
    },
    [TOKEN_REMOVER](state) {
      state.dadosUsuario = null
      state.autenticado = false
      state.cargoPermissoes = null
    },
    [ROTAS_ADICIONAR](state, payload) {
      state.cargoPermissoes = payload
    },
  },
  actions: {
    async realizaLoginStore({ commit }, dadosLoginDto) {
      const data = await usuarioService.login(dadosLoginDto)

      localstorage.setUserObj(data)
      commit(TOKEN_ADICIONAR, data)

      return data
    },
    sairDoSistemaStore({ commit }) {
      localstorage.removeUserObj()
      commit(TOKEN_REMOVER)
    },
    async buscaRotasPermissaoStore({ commit }, cargo) {
      const data = await cargoService.buscaPermissoesConfiguradas(cargo)
      localstorage.salvaPermissoesStorage(data)
      commit(ROTAS_ADICIONAR, data)
    },
  },
  getters: {
    estaAutenticado: (state) => state.autenticado,
    dadosUsuario: (state) => state.dadosUsuario,
    rotaMestre: (state) => state.cargoPermissoes.mestre ?? false,
    rotas: (state) => state.cargoPermissoes.permissoes ?? [],
  },
})
