<template>
  <div>
    <v-data-table
      :headers="colunas"
      :items="registros"
      hide-default-footer
      @click:row="clicouLinha"
    >
      <template
        v-for="colunaDinheiro in colunas.filter((coluna) =>
          coluna.hasOwnProperty('mascaraDinheiro')
        )"
        v-slot:[`item.${colunaDinheiro.value}`]="{ value }"
      >
        {{ value | currency }}
      </template>

      <template
        v-for="colunaBool in colunas.filter((coluna) =>
          coluna.hasOwnProperty('mascaraBool')
        )"
        v-slot:[`item.${colunaBool.value}`]="{ value }"
      >
        {{ value ? 'Sim' : 'Não' }}
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="totalPaginas"
        @input="mudarPagina"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    colunas: {
      type: Array,
      required: true,
    },
    registros: {
      type: Array,
      required: true,
    },
    totalPaginas: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
    }
  },
  methods: {
    clicouLinha(linha) {
      this.$emit('cliqueLinha', linha)
    },
    mudarPagina() {
      this.$emit('buscarPagina', this.page)
    },
  },
}
</script>
