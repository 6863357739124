import Vue from 'vue'
import InputBasico from '@/components/Inputs/Basico.vue'
import InputContato from '@/components/Inputs/Contato.vue'
import InputTextoLongo from '@/components/Inputs/TextoLongo.vue'
import InputCheckbox from '@/components/Inputs/Checkbox.vue'
import InputSelecao from '@/components/Inputs/Selecao.vue'
import InputDinheiro from '@/components/Inputs/Dinheiro.vue'
import InputCidade from '@/components/Inputs/Cidades/Cidade.vue'
import InputData from '@/components/Inputs/Data.vue'
import InputHora from '@/components/Inputs/Hora.vue'
import InputProduto from '@/components/Inputs/Produto.vue'
import InputEntregador from '@/components/Inputs/Entregador.vue'
import InputFormaPagamento from '@/components/Inputs/FormaPagamento.vue'
import InputCargo from '@/components/Inputs/Cargo.vue'

Vue.component('i-basico', InputBasico)
Vue.component('i-contato', InputContato)
Vue.component('i-texto-longo', InputTextoLongo)
Vue.component('i-check', InputCheckbox)
Vue.component('i-selecao', InputSelecao)
Vue.component('i-dinheiro', InputDinheiro)
Vue.component('i-cidade', InputCidade)
Vue.component('i-data', InputData)
Vue.component('i-hora', InputHora)
Vue.component('i-produto', InputProduto)
Vue.component('i-entregador', InputEntregador)
Vue.component('i-forma-pagamento', InputFormaPagamento)
Vue.component('i-cargo', InputCargo)
