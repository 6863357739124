export default {
  data() {
    return {
      loader: null,
    }
  },
  methods: {
    mostrarLoading() {
      if (this.loader) {
        this.loader.hide()
      }

      this.loader = this.$loading.show({
        isFullPage: true,
        canCancel: false,
        color: '#32a852',
        opacity: 0.4,
        backgroundColor: '#575757',
      })
    },
    fecharLoading() {
      setTimeout(() => {
        this.loader.hide()
      }, 100)
    },
  },
}
