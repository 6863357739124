<template>
  <validation-provider
    :vid="id"
    :name="id"
    :rules="validations"
    v-slot="{ errors }"
  >
    <v-autocomplete
      :label="label"
      :disabled="disabled"
      v-model="inputValue"
      :error-messages="errors"
      :items="registros"
      :loading="isLoading"
      :search-input.sync="search"
      :autocomplete="false"
      hide-no-data
      hide-selected
      item-text="nome"
      prepend-icon="mdi-database-search"
      return-object
    />
  </validation-provider>
</template>

<script>
import cargoService from '@/services/cargo.service'
import InputMixin from './InputMixin'

export default {
  mixins: [InputMixin],
  data() {
    return {
      registros: [],
      isLoading: false,
      search: null,
    }
  },
  mounted() {
    this.isLoading = true
    cargoService
      .buscaTodosSemLimite()
      .then((dados) => {
        if (dados.lista.length > 0) {
          this.registros = dados.lista
        }
      })
      .finally(() => (this.isLoading = false))
  },
}
</script>
