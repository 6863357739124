export const MSG_ERRO = 'MSG_ERRO'
export const MSG_ALERTA = 'MSG_ALERTA'
export const MSG_SUCESSO = 'MSG_SUCESSO'
export const MSG_INFO = 'MSG_INFO'

export default {
  gerar(tipo, arrMensagem, item = []) {
    try {
      if (arrMensagem.length <= 0) {
        return {
          tipo: MSG_ERRO,
          mensagens: [`erro_interno`],
        }
      }

      let arrRetorno = []
      if (typeof arrMensagem === 'string') {
        arrRetorno.push(arrMensagem)
      } else {
        arrRetorno = arrMensagem.map((item) => item.msg)
      }

      return { tipo, mensagens: arrRetorno, documento: item }
    } catch {
      return {
        tipo: MSG_ERRO,
        mensagens: [`erro_interno`],
      }
    }
  },
  retornaSomenteMensagens(arrMensagem) {
    try {
      const arrErros = []
      arrMensagem.forEach((item) => {
        if (item.msg) {
          arrErros.push(item.msg)
        }
      })

      return arrErros
    } catch {
      return []
    }
  },
}
