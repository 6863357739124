<template>
  <validation-provider
    :vid="id"
    :name="id"
    :rules="validations"
    v-slot="{ errors }"
  >
    <v-checkbox
      :label="label"
      v-model="inputValue"
      :error-messages="errors"
      :placeholder="placeholder"
      :disabled="disabled"
    />
  </validation-provider>
</template>

<script>
import InputMixin from './InputMixin'

export default {
  mixins: [InputMixin],
}
</script>
